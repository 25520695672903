import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import tw from "twin.macro";

import SectionTemplate from "../components/section";
import CardTemplate from "../components/card";
import CtaTemplate from "../components/cta";

const ServiceSection = ({ services }) => (
  <Section>
    {services.map(({ id, title, description, cta }, idx, arr) => {
      let zIndex;
      if (idx <= Math.round((arr.length - 1) / 2)) zIndex = idx;
      else zIndex = idx - Math.round((arr.length - 1) / 2) - 1;

      return (
        <Card title={title} style={{ zIndex }} key={id}>
          <Description>{description}</Description>
          {cta && <Cta to={cta.to}>{cta.text}</Cta>}
        </Card>
      );
    })}
  </Section>
);
ServiceSection.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.element.isRequired,
      cta: PropTypes.exact({
        to: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }),
    })
  ),
};
ServiceSection.defaultTypes = {
  services: [],
};

const Section = styled(SectionTemplate)`
  ${tw`flex-no-wrap`}
`;
const Card = styled(CardTemplate)`
  ${tw`relative w-11/12 max-w-sm mb-8 md:mb-0 md:-mx-1`}
  top: -3rem;
`;
const Description = styled.div`
  ${tw`my-2`}
  & ul {
    ${tw`list-disc list-inside`}
  }
`;
const Cta = styled(CtaTemplate)`
  ${tw`text-neutral-dark-700 border-neutral-dark-700 hover:text-primary-400 hover:border-primary-400`}
`;

export default ServiceSection;

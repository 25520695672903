import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import tw from "twin.macro";

import Section from "../components/section";
import Card from "../components/card";
import ContactForm from "../components/contact-form";

const ContactSection = ({ translations }) => {
  const {
    site: {
      siteMetadata: { phone },
    },
  } = useStaticQuery(graphql`
    query ContactSectionQuery {
      site {
        siteMetadata {
          phone
        }
      }
    }
  `);
  return (
    <Section>
      <ContactCard title="Kontakt">
        <ContactInfoList>
          <ContactInfo>
            ul. Rejtana 2-4
            <br />
            87-100 Toruń
          </ContactInfo>
          <ContactInfo>
            {translations.phone}: {phone}
          </ContactInfo>
          <ContactInfo>
            {translations.email}:{" "}
            <a href="mailto:biuro@finanse-dekret.pl">biuro@finanse-dekret.pl</a>
          </ContactInfo>
          <ContactInfo>NIP: 879-212-31-58</ContactInfo>
          <ContactInfo>REGON: 341224623</ContactInfo>
        </ContactInfoList>
        <ContactForm translations={translations} />
      </ContactCard>
    </Section>
  );
};
ContactSection.propTypes = {
  translations: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    send: PropTypes.string.isRequired,
  }).isRequired,
};

const ContactCard = styled(Card)`
  ${tw`relative w-11/12 max-w-xl p-4`}
  top: -3rem;
`;
const ContactInfoList = styled.ul`
  ${tw`max-w-md`}
  min-width: 33%;
`;
const ContactInfo = styled.li`
  ${tw`mb-2 leading-none`}
`;

export default ContactSection;

import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import HeroSection from "../components/hero-section";
import ServiceSection from "../components/service-section";
import AboutSection from "../components/about-section";
import ContactSection from "../components/contact-section";

import logo from "../pages/images/logo.png";

export default ({ data, pageContext }) => {
  const page = {
    url: data.site.siteMetadata.siteUrl,
    logo,
    ...data.site.siteMetadata,
    ...data.page.frontmatter,
  };
  const topLevelMenu = [
    {
      to: `${pageContext.slug}#services`,
      title: "Oferta",
      id: "1",
    },
    {
      to: `${pageContext.slug}#aboutus`,
      title: "O Nas",
      id: "2",
    },
    {
      to: `${pageContext.slug}#contact`,
      title: "Kontakt",
      id: "3",
    },
  ];
  const hero = {
    image: page.image.childImageSharp.fluid,
    primary: page.heroPrimary,
    secondary: page.heroSecondary,
    actions: page.heroActions,
    texts: page.heroRotatingTexts,
  };
  const aboutUsMarkdown = page.aboutUs.childMarkdownRemark;
  const aboutUs = {
    title: aboutUsMarkdown.frontmatter.title,
    content: aboutUsMarkdown.html,
  };
  const members = data.team.nodes.map(member => ({
    name: member.frontmatter.name,
    jobTitle: member.frontmatter.jobTitle,
    email: member.frontmatter.email,
    image: member.frontmatter.image.childImageSharp.fluid,
    description: member.html,
    order: member.frontmatter.order,
    id: member.id,
  }));
  const services = data.services.nodes.map(service => {
    const description = (
      <ul>
        {service.headings.map(({ value }, idx) => (
          <li key={idx}>{value}</li>
        ))}
      </ul>
    );

    return {
      id: service.id,
      title: service.frontmatter.title,
      cta: service.frontmatter.cta,
      description,
    };
  });

  return (
    <Layout
      pageContext={pageContext}
      menu={topLevelMenu}
      lang={pageContext.lang}
      description={page.description}
      schema={`
      {
        "@context": "http://schema.org",
        "@type": "AccountingService",
        "@id": "${page.url}",
        "url": "${page.url}",
        "name": "${page.author}",
        "logo": "${page.url + page.logo}",
        "email": "${page.email}",
        "telephone": "${page.phone}",
        "description": "${page.description}",
        "sameAs": [
          "${page.facebook}"
        ],
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "${page.address.street}",
          "addressLocality": "${page.address.city}",
          "postalCode": "${page.address.postalCode}",
          "addressRegion": "${page.address.county}",
          "addressCountry": "${page.address.country}"
        },
        "image": "${page.url + page.logo}"
      }
      `}
    >
      <div id="hero" className="hashlink"></div>
      <HeroSection
        image={hero.image}
        title={{ primary: hero.primary, secondary: hero.secondary }}
        actions={hero.actions}
        texts={hero.texts}
      />
      <div id="services" className="hashlink"></div>
      <ServiceSection services={services} />
      <div id="aboutus" className="hashlink"></div>
      <AboutSection members={members} aboutUs={aboutUs} />
      <div id="contact" className="hashlink"></div>
      <ContactSection
        translations={{
          name: "Imię",
          email: "Email",
          phone: "Telefon",
          message: "Wiadomość",
          send: "Wyślij",
        }}
      />
    </Layout>
  );
};

export const query = graphql`
  query indexQuery($lang: String!) {
    page: markdownRemark(
      fields: { relativePath: { eq: "/" }, lang: { eq: $lang } }
    ) {
      frontmatter {
        title
        aboutUs {
          childMarkdownRemark {
            frontmatter {
              title
            }
            html
          }
        }
        heroPrimary
        heroSecondary
        heroRotatingTexts
        heroActions {
          text
          to
        }
        image {
          childImageSharp {
            fluid(maxWidth: 2560, quality: 85) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    team: allMarkdownRemark(
      filter: { fields: { lang: { eq: $lang }, directory: { eq: "/team/" } } }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      nodes {
        frontmatter {
          name
          jobTitle
          email
          image {
            childImageSharp {
              fluid(maxWidth: 270) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          order
        }
        html
        id
      }
    }

    services: allMarkdownRemark(
      filter: {
        fields: { lang: { eq: $lang }, directory: { eq: "/services/" } }
      }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      nodes {
        frontmatter {
          title
          cta {
            to
            text
          }
          order
        }
        fields {
          slug
        }
        headings(depth: h4) {
          value
        }
        id
      }
    }

    site {
      siteMetadata {
        siteUrl
        title
        description
        author
        phone
        email
        address {
          street
          city
          postalCode
          county
          country
        }
        facebook
      }
    }
  }
`;

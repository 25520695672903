import React from "react";
import PropTypes from "prop-types";
import GatsbyImg from "gatsby-image";
import styled from "@emotion/styled";
import tw from "twin.macro";

const Member = ({
  name,
  jobTitle,
  email = "",
  image,
  description,
  id,
  ...props
}) => (
  <Container {...props}>
    <Img fluid={image} />
    <Content>
      <Info>
        <Name>{name}</Name>
        <Job>{jobTitle}</Job>
        <Email>{email}</Email>
      </Info>
      <Description
        dangerouslySetInnerHTML={{ __html: description }}
      ></Description>
    </Content>
  </Container>
);
Member.propTypes = {
  name: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  email: PropTypes.string,
  image: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
};

const Container = styled.div`
  ${tw`flex flex-col items-center w-full my-6 sm:flex-row md:flex-col`}
`;
const Img = styled(GatsbyImg)`
  ${tw`w-full mb-2 sm:mr-2`}
  max-width: 10rem;
`;
const Content = styled.div`
  ${tw`max-w-xs my-2`}
`;
const Info = styled.div`
  ${tw`max-w-xs my-2`}
`;
const Name = styled.h3`
  ${tw`leading-none`}
`;
const Job = styled.h6`
  ${tw`leading-tight uppercase text-neutral-dark-300`}
`;
const EmailHelper = ({ children, ...props }) =>
  children && (
    <a href={`mailto:${children}`} {...props}>
      {children}
    </a>
  );
const Email = styled(EmailHelper)`
  ${tw`text-sm align-top`}

  &:before {
    content: "";
    width: ;
  }
`;
const Description = styled.div`
  ${tw`max-w-xs break-words sm:ml-2`}

  & ul {
    ${tw`leading-tight list-disc list-inside`}
  }
`;

export default Member;

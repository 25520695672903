import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import tw from "twin.macro";

import SectionTemplate, { Row as RowTemplate } from "../components/section";
import Card from "../components/card";
import Member from "../components/member";

import swirl from "../pages/images/swirl.png";

const AboutSection = ({ members, aboutUs }) => {
  const leader = members.shift();

  return (
    <Section>
      <Row>
        <AboutCard title={aboutUs.title}>
          <AboutDescription
            dangerouslySetInnerHTML={{ __html: aboutUs.content }}
          />
        </AboutCard>
      </Row>

      <Row>
        <MembersCard title="Zespół">
          <Member {...leader}></Member>
          <MemberRow>
            {members.map((member) => (
              <Member key={member.id} {...member}></Member>
            ))}
          </MemberRow>
        </MembersCard>
      </Row>
    </Section>
  );
};
AboutSection.propTypes = {
  members: PropTypes.arrayOf(PropTypes.exact(Member.propTypes)).isRequired,
  aboutUs: PropTypes.exact({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
};

const Section = styled(SectionTemplate)`
    ${tw`bg-primary-200`}
    background-image: url("${swirl}");
  `;
const Row = styled(RowTemplate)`
  ${tw`mb-8`}
`;
const AboutCard = styled(Card)`
  ${tw`relative w-11/12 max-w-2xl px-8 pt-4 pb-8 md:px-4`}
  top: -3rem;
`;
const AboutDescription = styled.div`
  ${tw`max-w-xl leading-relaxed`}
  & p:not(:last-child) {
    ${tw`mb-2`}
  }
`;

const MembersCard = styled(Card)`
  ${tw`relative w-11/12 max-w-2xl p-4`}
  top: -3rem;
`;
const MemberRow = styled.div`
  ${tw`flex flex-col items-start w-full md:flex-row`}
`;

export default AboutSection;

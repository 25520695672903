import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import tw from "twin.macro";

import SectionTemplate, { Row } from "./section";
import { Link } from "gatsby";
import GatsbyImg from "gatsby-image";
import ReactRotatingText from "react-rotating-text";

const HeroSection = ({ image, title, actions, texts }) => {
  if (!Array.isArray(actions)) actions = [actions];
  return (
    <Section>
      <Row>
        <Headings>
          <Title>{title.primary}</Title>
          <SecondaryTitle>{title.secondary}</SecondaryTitle>
          <CtaButtons>
            {actions.map((action) =>
              action.isPage ? (
                <PageButton to={action.to} key={action.to}>
                  {action.text}
                </PageButton>
              ) : (
                <MiscButton href={action.to} key={action.to}>
                  {action.text}
                </MiscButton>
              )
            )}
          </CtaButtons>
        </Headings>
      </Row>
      <Row>
        <RotatingTextContainer>
          <RotatingText items={texts} />
        </RotatingTextContainer>
        <Img fluid={image} />
      </Row>
    </Section>
  );
};
HeroSection.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.exact({
    primary: PropTypes.string,
    secondary: PropTypes.string,
  }),
  actions: PropTypes.arrayOf(
    PropTypes.exact({
      to: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      isPage: PropTypes.bool,
    })
  ),
  texts: PropTypes.arrayOf(PropTypes.string),
};

const Section = styled(SectionTemplate)`
  ${tw`relative text-white sm:py-32`}
`;
const Headings = styled.div`
  ${tw`my-8 leading-none whitespace-pre-wrap sm:-ml-24`}
`;
const Title = styled.h1``;
const SecondaryTitle = styled.h2``;
const CtaButtons = styled.div`
  ${tw`flex`}

  & *:first-of-type {
    ${tw`ml-0`}
  }
`;
const PageButton = styled(Link)`
  ${tw`inline-block p-2 mx-2 my-4 font-semibold border-2 top-1 text-accent-400 border-accent-400 hover:text-white hover:border-white`}
`;
const MiscButton = styled.a`
  ${tw`inline-block p-2 mx-2 my-4 font-semibold border-2 top-1 text-accent-400 border-accent-400 hover:text-white hover:border-white`}
`;
const RotatingTextContainer = styled.div`
  ${tw`max-w-sm mx-2 mb-4 text-lg sm:text-xl sm:whitespace-no-wrap`}
  height: 3rem;

  & .react-rotating-text-cursor {
    animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s
      infinite;
  }

  @keyframes blinking-cursor {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
const RotatingText = styled(ReactRotatingText)``;

// Position must be !important because only then it overrides
// gatsby-image's default inline position relative.
const Img = styled(GatsbyImg)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -9999;
`;

export default HeroSection;

import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import tw from "twin.macro";

const Card = ({ title, children, ...props }) => {
  return (
    <Container {...props}>
      <Title>{title}</Title>
      {children}
    </Container>
  );
};
Card.propTypes = {
  title: PropTypes.string.isRequired,
};

const Container = styled.div`
  ${tw`flex flex-col items-center justify-center p-6 bg-white rounded shadow-lg`}
`;
const Title = styled.h2`
  ${tw`w-full text-center text-primary-500`}
`;

export default Card;

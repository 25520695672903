import { useStaticQuery, graphql } from "gatsby";
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import tw from "twin.macro";

const ContactForm = ({ translations }) => {
  const {
    site: {
      siteMetadata: { formSubmitLink },
    },
  } = useStaticQuery(graphql`
    query ContactFormQuery {
      site {
        siteMetadata {
          formSubmitLink
        }
      }
    }
  `);
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  const objectToUriEncoded = (object) => {
    const params = new URLSearchParams(object);
    return params.toString();
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    fetch(formSubmitLink, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: objectToUriEncoded(formState),
    });
  };

  return (
    <Form onSubmit={handleFormSubmit} key="contactForm">
      <Label htmlFor="name">{translations.name}: </Label>
      <Input
        name="name"
        type="text"
        value={formState.name}
        onChange={handleInputChange}
        key="contactFormName"
        required
      ></Input>

      <Label htmlFor="email">{translations.email}: </Label>
      <Input
        name="email"
        type="email"
        value={formState.email}
        onChange={handleInputChange}
        key="contactFormEmail"
        required
      ></Input>

      <Label htmlFor="message">{translations.message}: </Label>
      <Textarea
        name="message"
        value={formState.message}
        onChange={handleInputChange}
        key="contactFormMessage"
        required
      ></Textarea>

      <Submit type="submit">{translations.send}</Submit>
    </Form>
  );
};
ContactForm.propTypes = {
  translations: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    send: PropTypes.string.isRequired,
  }).isRequired,
};

const Form = styled.form`
  ${tw`flex flex-col items-start w-3/4 sm:max-w-md`}
`;
const Label = styled.label``;
const Input = styled.input`
  ${tw`w-full px-1 mb-2 border border-neutral-dark-500`}
`;
const Textarea = styled.textarea`
  ${tw`w-full h-48 px-1 mb-2 border border-neutral-dark-500`}
`;
const Submit = styled.button`
  ${tw`max-w-xs px-4 py-1 m-auto mt-4 border-2 border-neutral-dark-500 hover:border-primary-400 hover:text-primary-400`}
`;

export default ContactForm;
